<template>
  <div class="container">
    <div class="col-md-12 d-flex justify-content-between">
      <h4 class="finances-title">{{ $t("message.financial") }}</h4>
    </div>
    <div class="mt-lg-3 col-md-12 box p-lg-4 p-4">
      <div class="d-flex mt-lg-2">
        <!-- Bootstrap classes -->
        <div class="flex-column">
          <strong class="balance-label">Saldo em caixa</strong>
          <h3
            class="balance-value mt-lg-2 mt-md-2 mt-sm-2 mt-2 mb-3 mb-sm-1 mb-md-1 mb-lg-1"
          >
            {{ $t("message.currentCoin") }} 0,00
          </h3>
        </div>
      </div>
      <div class="mt-lg-2">
        <button
          type="button"
          class="btn-yellow-outlined me-2 order-2"
          @click="redirectToWithDraw"
        >
          {{ $t("message.withdraw") }}
        </button>
        <button
          type="button"
          class="btn-yellow me-2 order-2"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          {{ $t("message.depositary") }}
        </button>
      </div>
    </div>
    <div class="mt-lg-3 mt-3 col-md-12">
      <table class="table table-striped rounded">
        <thead class="thead-dark">
          <tr>
            <th>Operação</th>
            <th>Saldo</th>
            <th>Valor</th>
            <th class="text-center">Data da operação</th>
          </tr>
        </thead>
        <tbody class="tbody-dark">
          <tr v-for="jogador in jogadores" :key="jogador.id">
            <td>
              <i
                v-if="jogador.operacao === 'Deposito'"
                class="fa-solid fa-up-long"
              ></i
              ><i v-else class="fa-solid fa-down-long"></i>
              {{ jogador.operacao }}
            </td>
            <td>{{ $t("message.currentCoin") }} {{ jogador.saldo }}</td>
            <td v-if="jogador.operacao === 'Deposito'" id="deposit-money">
              {{ "+" + jogador.valor }}
            </td>
            <td v-else id="withdraw-money">
              {{ "-" + jogador.valor }}
            </td>
            <td class="text-center">12/12/2023</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinancesPage",
  data() {
    return {
      jogadores: [
        {
          operacao: "Saque",
          saldo: "10.734.842,95",
          valor: "1.000,00",
        },
        {
          operacao: "Deposito",
          saldo: "9.223.189,33",
          valor: "500,00",
        },
        {
          operacao: "Saque",
          saldo: "9.223.189,33",
          valor: "800,12",
        },
        {
          operacao: "Saque",
          saldo: "10.734.842,95",
          valor: "1.000,00",
        },
        {
          operacao: "Deposito",
          saldo: "9.223.189,33",
          valor: "500,00",
        },
        {
          operacao: "Saque",
          saldo: "9.223.189,33",
          valor: "800,12",
        },
        {
          operacao: "Saque",
          saldo: "10.734.842,95",
          valor: "1.000,00",
        },
        {
          operacao: "Deposito",
          saldo: "9.223.189,33",
          valor: "500,00",
        },
        {
          operacao: "Saque",
          saldo: "9.223.189,33",
          valor: "800,12",
        },
      ],
    };
  },
  methods: {
    redirectToWithDraw() {
      this.$router.push({
        name: "Withdraw",
      });
    },
  },
  components: {},
};
</script>

<style>
@import url(@/views/styles/finances.css);
</style>
