<template>
  <!-- Cabeçalho -->
  <nav class="navbar navbar-expand-lg pt-lg-2 pt-2">
    <div class="container">
      <!-- Logo do site -->
      <a
        href="/"
        class="navbar-brand me-2 d-flex align-items-center justify-content-center"
      >
        <img
          src="@/components/assets/imgs/logo.svg"
          height="55"
          loading="lazy"
          style="margin-top: -1px"
        />
        <h2 class="title-site ms-2">playchess<strong>4.com</strong></h2>
      </a>

      <div class="responsive-mobile">
        <ul class="navbar-nav me-auto mb-2"></ul>

        <div class="d-flex align-items-center">
          <button
            v-if="isAuthenticated"
            type="button"
            class="btn-yellow-outlined me-2 flex-nowrap"
            @click="redirectToFinances"
          >
            R$ 12.321,11
          </button>
          <button
            v-if="isAuthenticated"
            type="button"
            class="btn-yellow me-3"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            Depositar
          </button>
          <button v-else type="button" class="btn btn-yellow me-3">
            Cadastrar
          </button>

          <div v-if="isAuthenticated" class="me-1 dropdown">
            <img
              class="user-profile"
              src="@/components/assets/imgs/user.svg"
              alt="Foto do usuário"
              id="userDropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            />
            <div class="dropdown">
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="userDropdown"
              >
                <!-- Dropdown items go here -->

                <li class="mt-lg-2 mb-lg-2 mt-md-2 mb-md-2 mb-2 mt-2">
                  <a class="dropdown-item" href="/perfil"
                    ><i class="fa-solid fa-user"></i>Meu perfil</a
                  >
                </li>
                <li class="mt-lg-2 mb-lg-2 mt-md-2 mb-md-2 mb-2 mt-2">
                  <a class="dropdown-item" href="/amigos"
                    ><i class="fa-solid fa-user-group"></i>Amigos</a
                  >
                </li>
                <li class="mt-lg-2 mb-lg-2 mt-md-2 mb-md-2 mb-2 mt-2">
                  <a class="dropdown-item" href="/caixa-de-entrada"
                    ><i id="chat" class="fa-solid fa-paper-plane"> </i>Chat</a
                  >
                </li>
                <li class="mt-lg-2 mb-lg-2 mt-md-2 mb-md-2 mb-2 mt-2">
                  <a class="dropdown-item" href="/entrar">
                    <i class="fa-solid fa-arrow-right-from-bracket"></i>Sair</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div v-else>
            <button
              v-if="isAuthenticated"
              type="button"
              class="btn btn-yellow-outlined me-2"
            >
              Depositar
            </button>
            <button v-else type="button" class="btn btn-yellow-outlined me-2">
              Entrar
            </button>
          </div>
        </div>
      </div>

      <!-- Conteúdo da barra de navegação -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto mb-2"></ul>
        <div class="dropdown">
          <button
            class="select-country dropdown-toggle me-lg-3"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              :src="selectedCountry.flag"
              class="flag-icon"
              v-if="selectedCountry.flag"
            />
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li v-for="country in countries" :key="country.code">
              <a class="dropdown-item" @click.prevent="selectCountry(country)">
                <img :src="country.flag" class="flag-icon" /> {{ country.name }}
              </a>
            </li>
          </ul>
        </div>
        <div class="d-flex align-items-center">
          <button
            @click="redirectToFinances"
            v-if="isAuthenticated"
            type="button"
            class="btn-yellow-outlined me-2 order-1"
          >
            {{ $t("message.currentCoin") }} 32.764,44
          </button>
          <div v-else></div>
          <button
            v-if="isAuthenticated"
            type="button"
            class="btn-yellow me-2 order-2"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            {{ $t("message.depositary") }}
          </button>
          <button v-else type="button" class="btn btn-yellow me-2 order-1">
            Cadastrar
          </button>
          <div v-if="isAuthenticated">
            <div class="dropdown">
              <span class="badge">1</span
              ><img
                class="user-profile me-3"
                src="@/components/assets/imgs/user.svg"
                alt="Foto do usuário"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              />
              <ul class="dropdown-menu mt-2">
                <li>
                  <a class="dropdown-item mt-lg-2 mb-lg-2" href="/perfil"
                    ><i class="fa-solid fa-user"></i
                    >{{ $t("message.myProfile") }}</a
                  >
                </li>
                <li>
                  <a class="dropdown-item mt-lg-2 mb-lg-2" href="/amigos"
                    ><i class="fa-solid fa-user-group"></i
                    >{{ $t("message.friends") }}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item mt-lg-2 mb-lg-2 flex-nowrap"
                    href="/caixa-de-entrada"
                    ><span class="badge">9</span
                    ><i id="chat" class="fa-solid fa-paper-plane"> </i>Chat</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item mt-lg-2 mb-lg-2 mt-5 flex-nowrap"
                    href="/entrar"
                  >
                    <i class="fa-solid fa-arrow-right-from-bracket"> </i
                    >{{ $t("message.exit") }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <button
              v-if="isAuthenticated"
              type="button"
              class="btn-yellow-outlined me-2 order-2"
            >
              Depositar
            </button>
            <button
              v-else
              type="button"
              class="btn-yellow-outlined me-2 order-2"
            >
              Entrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <RechargeModal />
  </nav>
</template>

<script>
import RechargeModal from "@/components/modals/RechargeModal.vue";

export default {
  name: "HeaderDashboard",
  data() {
    return {
      isAuthenticated: true,
      selectedCountry: {
        flag: "https://static.todamateria.com.br/upload/ba/nd/bandeira-do-brasil-og.jpg",
      },
      countries: [
        {
          name: "Brasil",
          flag: "https://static.todamateria.com.br/upload/ba/nd/bandeira-do-brasil-og.jpg",
          code: "BR",
        },
        {
          name: "EUA",
          flag: "https://static.mundoeducacao.uol.com.br/mundoeducacao/2022/05/bandeira-estados-unidos.jpg",
          code: "US",
        },
      ],
    };
  },
  components: {
    RechargeModal,
  },

  methods: {
    selectCountry(country) {
      this.selectedCountry = country;
      if (country.name === "EUA") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "br";
      }
    },
    redirectToFinances() {
      this.$router.push({
        name: "Finances",
      });
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style>
@import url(@/components/assets/css/buttons.css);
@import url(@/components/assets/css/header.css);
@import url(@/components/assets/css/responsive-mobile.css);

nav {
  background-color: transparent;
}
.white-icon {
  color: white;
}
</style>
