<template>
  <div class="container">
    <div class="row d-flex justify-content-between flex-wrap">
      <div class="col-lg-7 col-md-7 col-sm-12">
        <TheChessboard
          draggable="true"
          class="side-left-game mt-lg-1 mb-lg-3 rounded"
          @check="checkWin"
        />
      </div>

      <div class="col-md-5 col-12">
        <div class="flex-column">
          <div class="side-right-game mt-lg-1 mb-lg-3 mt-3 mb-3 p-lg-4 p-3">
            <div>
              <div
                class="flex-row d-flex align-items-center justify-content-between"
              >
                <div class="flex-column">
                  <span class="mt-lg-1 clock-text">
                    seu tempo: <span class="secounds">{{ timer }}s</span>
                    <i class="fa-solid fa-clock"></i
                  ></span>
                  <h3 class="side-title mt-lg-2 mt-2">
                    Estatísticas<i class="fa-solid fa-chart-line"></i>
                  </h3>
                </div>
                <div>
                  <a
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </a>
                  <ul class="dropdown-menu mt-2">
                    <li>
                      <a
                        class="dropdown-item mt-lg-2 mb-lg-2"
                        style="cursor: pointer"
                        ><i class="fa-solid fa-triangle-exclamation"></i>Sugerir
                        desistência</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item mt-lg-2 mb-lg-2"
                        @click="showConfirmAlert"
                        style="cursor: pointer"
                        ><i
                          class="fa-solid fa-person-walking-dashed-line-arrow-right"
                        ></i
                        >Desistir</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="flex-row flex-wrap d-flex bg-footer-card align-center-center justify-content-between p-lg-3 p-3 mt-lg-3 mt-2 rounded"
              >
                <div class="col-md-6">
                  <div class="flex-column me-1">
                    <h3 class="player-name">
                      Saraiva<img
                        class="country"
                        width="24"
                        src="@/components/assets/imgs/brazil-icon.svg"
                      />
                    </h3>
                    <h3 class="player-value">
                      {{ $t("message.currentCoin") }} 10.000,00
                    </h3>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="flex-column me-1">
                    <h3 class="player-name">
                      Bruno<img
                        class="country"
                        width="24"
                        src="@/components/assets/imgs/brazil-icon.svg"
                      />
                    </h3>
                    <h3 class="player-value">
                      {{ $t("message.currentCoin") }} 10.000,00
                    </h3>
                  </div>
                </div>
              </div>

              <div
                class="flex-row flex-wrap d-flex bg-footer-card align-content-center justify-content-between p-lg-3 p-3 mt-lg-3 mt-2 rounded"
              >
                <div class="flex-column me-1">
                  <h3 class="label">Suas peças</h3>
                  <h3 class="tax-value">Pretas</h3>
                </div>
                <div class="flex-column me-1">
                  <h3 class="label">Multiplicador</h3>
                  <h3 class="tax-value">2x</h3>
                </div>
                <div class="flex-column me-1">
                  <h3 class="label">Lucro</h3>
                  <h3 class="tax-value">{{ $t("message.currentCoin") }}9,45</h3>
                </div>
                <div class="flex-column me-1">
                  <h3 class="label">Taxa</h3>
                  <h3 class="tax-value">20%</h3>
                </div>
              </div>
              <div
                style="height: 340px"
                class="bg-footer-chat overflow-y-scroll justify-content-between mt-lg-3 mt-2 rounded"
              >
                <div
                  class="chat-header position-sticky top-0 p-2 text-center align-items-center justify-content-between"
                >
                  <h4 style="margin: 0">Chat ao vivo</h4>
                </div>
                <div class="card-body p-lg-3 p-3">
                  <div class="flex-column d-flex">
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        >Saraiva: <span>Olá</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        >Bruno: <span>Fala pitango!🐒</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        >Bruno: <span>Bão netflixo🐒</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        >Saraiva: <span>Olá🐒</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        >Bruno: <span>Fala pitango!</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        >Bruno: <span>Bão netflixo</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        >Saraiva: <span>Olá</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        >Bruno: <span>Fala pitango!</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        ><img
                          class="me-1"
                          width="24"
                          height="24"
                          src="@/components/assets/imgs/user.svg"
                          alt=""
                        />Bruno: <span>Bão netflixo</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        ><img
                          class="me-1"
                          width="24"
                          height="24"
                          src="@/components/assets/imgs/user.svg"
                          alt=""
                        />Saraiva: <span>Olá</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        ><img
                          class="me-1"
                          width="24"
                          height="24"
                          src="@/components/assets/imgs/user.svg"
                          alt=""
                        />Bruno: <span>Fala pitango!</span></strong
                      >
                    </div>
                    <div class="mt-2 mb-2">
                      <strong class="chat-user-name"
                        >Bruno: <span>Bão netflixo</span></strong
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="chat-header position-sticky bottom-0 p-2 text-center align-items-center justify-content-between"
                >
                  <div class="flex-row">
                    <span class="me-2">denunciar</span>
                    <i
                      class="fa-solid fa-triangle-exclamation"
                      style="color: #ffff00"
                    ></i>
                  </div>
                  <div class="flex-row">
                    <i class="fa-solid fa-user-plus" style="color: #ffffff"></i>
                    <i
                      class="fa-solid fa-face-smile"
                      style="color: #ffffff"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <input
              class="input-message text-light mt-lg-2 mb-lg-2 mt-2 mb-2"
              type="text"
              placeholder="Enviar uma mensagem..."
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TheChessboard } from "vue3-chessboard";
import "vue3-chessboard/style.css";
import moveSound from "@/components/sounds/move-self.mp3";
import { toast } from "vue3-toastify";

export default {
  name: "GamesRoom",
  data() {
    return {
      timer: 900,
      position: "",
    };
  },
  setup() {},
  components: { TheChessboard },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      const timer = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(timer);
        }
      }, 1000); // diminui a cada segundo
    },
    showGameSound() {
      const audio = new Audio(moveSound);
      audio.play();
    },
    showGameSoundCheck() {},
    showConfirmAlert() {
      // Usar confirm para obter uma resposta de Sim ou Não
      let response = confirm("Clique em OK para 'Sim' ou Cancelar para 'Não'");

      if (response) {
        // Código a ser executado se a resposta for Sim
        this.$router.push({
          name: "Home",
        });
      } else {
        return null;
      }
    },
    handleCheckmate(isMated) {
      if (isMated === "w") {
        alert("Black wins!");
      } else {
        alert("White wins!");
      }
    },
    checkWin() {
      toast("Checkwin! você ganhou R$ 7.000,00🚀👏♟️🎉", {
        autoClose: 3000,
        closeOnClick: false,
        onClose: () =>
          this.$router.push({
            name: "Home",
          }),
        position: "top-center",
        type: "success",
        theme: "colored",
      }); // ToastOptions
    },
  },
};
</script>

<style>
@import url(@/views/styles/games-room.css);
</style>
