<template>
  <div class="container py-lg-3">
    <h2 class="title text-center">Termos de uso</h2>
    <div class="termos-de-uso">
      <h2>Introdução</h2>
      <p>
        Ao acessar e usar este site, você aceita e concorda em cumprir os termos
        e condições que estão descritos a seguir.
      </p>

      <h2>Uso do Serviço</h2>
      <p>
        Este serviço é fornecido "como está" e "como disponível". Você não pode
        usar este serviço para atividades ilegais ou não autorizadas.
      </p>

      <h2>Alterações no Serviço e nos Termos</h2>
      <p>
        Reservamo-nos o direito de modificar ou descontinuar o serviço com ou
        sem aviso prévio. Também podemos alterar estes termos a qualquer momento
        e sua continuidade de uso do site será considerada como sua aceitação
        das mudanças.
      </p>

      <h2>Privacidade</h2>
      <p>
        Sua privacidade é importante para nós. Nossa política de privacidade
        explica quais informações pessoais coletamos e como as utilizamos.
      </p>

      <h2>Contato</h2>
      <p>
        Para qualquer dúvida ou preocupação sobre estes termos, por favor, entre
        em contato conosco através do e-mail contato@seusite.com.
      </p>

      <h2>Lei Aplicável</h2>
      <p>
        Este acordo será regido e interpretado de acordo com as leis do Brasil,
        sem consideração a qualquer escolha de princípios legais.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
  components: {},
};
</script>

<style>
@import url(@/views/styles/terms-use.css);
</style>
