<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="selectTheValueModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <!-- Adiciona classe modal-lg para uma versão mais ampla do modal em telas grandes -->
      <div class="modal-content p-3 p-lg-3">
        <!-- Cabeçalho do Modal -->
        <div
          class="modal-header align-items-center justify-content-between w-100"
        >
          <h2 class="modal-title">{{ $t("message.playGame") }}</h2>
          <i class="fa-solid fa-circle-xmark fs-3" data-bs-dismiss="modal"></i>
        </div>

        <!-- Corpo do Modal -->
        <div class="modal-body text-center">
          <!-- Quantidade Atual de Moedas -->
          <div class="d-flex align-items-center justify-content-center">
            <h3 class="amount">
              {{ $t("message.currentCoin") }} {{ amount.toFixed(2) }}
            </h3>
          </div>

          <div
            class="d-flex flex-md-nowrap flex-lg-nowrap flex-wrap justify-content-center mt-2"
          >
            <div
              @click="increaseAmount1"
              class="badges-value-unselected me-2 mt-lg-2 mb-lg-2 mt-md-2 mb-2"
            >
              +{{ $t("message.currentCoin") }} 1,00
            </div>
            <div
              @click="increaseAmount5"
              class="badges-value-unselected me-2 mt-lg-2 mb-lg-2 mt-md-2 mb-2"
            >
              +{{ $t("message.currentCoin") }} 5,00
            </div>
            <div
              @click="increaseAmount10"
              class="badges-value-unselected me-2 mt-lg-2 mb-lg-2 mt-md-2 mb-2"
            >
              +{{ $t("message.currentCoin") }} 10,00
            </div>
          </div>

          <!-- Seleção de Distintivos -->
          <div class="d-flex justify-content-center mt-2">
            <!-- Restante do código para os distintivos -->
          </div>

          <!-- Informações Adicionais -->
          <div class="row mt-lg-3 mt-2">
            <div class="col-md-4 col-sm-12 mb-lg-2 mb-2">
              <!-- Conteúdo para "Ganhe até" -->
            </div>
            <div class="col-md-4 col-sm-12 mb-lg-2 mb-2">
              <!-- Conteúdo para "Faturamento previsto" -->
            </div>
            <div class="col-md-4 col-sm-12 mb-lg-2 mb-2">
              <!-- Conteúdo para "Taxa da plataforma" -->
            </div>
          </div>
        </div>

        <!-- Rodapé do Modal -->
        <div
          class="modal-footer d-flex text-center align-items-center justify-content-center"
        >
          <p class="account-balance mb-3">
            {{ $t("message.accountBalance") }}: R$ 13.872,11
          </p>

          <!-- Botão de Jogar -->
          <button
            class="w-100 rounded-btn btn-play-lg"
            @click="redirectToFindPlayers"
            data-bs-dismiss="modal"
          >
            {{ $t("message.play") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BetAmountModal",
  data() {
    return {
      amount: 2.0, // Valor inicial
      isPaymentMode: false,
      code: "",
      customAmount: 2.0, // Nova propriedade para texto editável
    };
  },
  methods: {
    // Métodos para aumentar/diminuir o valor
    increaseAmount1() {
      this.amount += 1.0;
    },
    increaseAmount5() {
      this.amount += 5.0;
    },
    increaseAmount10() {
      this.amount += 10.0;
    },
    decreaseAmount() {
      if (this.amount > 1.0) {
        this.amount -= 1.0;
      }
    },
    // Redireciona para a página de busca de jogadores
    redirectToFindPlayers() {
      this.$router.push({
        name: "FindPlayer",
      });
    },
  },
};
</script>

<style>
/* Importa o estilo do componente específico */
@import url(@/components/assets/css/select-value.css);
</style>
