<template>
  <div id="app">
    <HeaderDashboard />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import HeaderDashboard from "./views/Layout/HeaderDashboard.vue";

export default {
  name: "App",
  components: { HeaderDashboard },
};
</script>

<style>
@import url(@/components/assets/css/globalStyles.css);
</style>
