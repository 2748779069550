import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/app/Home.vue";
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import RecoveryPassword from "@/views/auth/RecoveryPassword.vue";

import FindPlayer from "@/views/app/FindPlayer.vue";
import MyProfile from "@/views/app/MyProfile.vue";
import GamesRoom from "@/views/app/GamesRoom.vue";
import TermsOfUse from "@/views/app/TermsOfUse.vue";
import Finances from "@/views/app/Finances.vue";
import Friends from "@/views/app/Friends.vue";
import Withdraw from "@/views/app/Withdraw.vue";
import Chat from "@/views/app/Chat.vue";

const routes = [
  {
    path: "/",
    redirect: {
      name: "Home",
    },
  },
  {
    path: "/cadastro",
    name: "Register",
    component: Register,
  },
  {
    path: "/entrar",
    name: "Login",
    component: Login,
  },
  {
    path: "/recuperar-senha",
    name: "RecoveryPassword",
    component: RecoveryPassword,
  },
  {
    path: "/pagina-de-inicio",
    name: "Home",
    component: Home,
  },
  {
    path: "/buscando-um-oponente",
    name: "FindPlayer",
    component: FindPlayer,
  },
  {
    path: `/perfil`,
    name: "Profile",
    component: MyProfile,
  },
  {
    path: "/sala-de-jogos",
    name: "GamesRoom",
    component: GamesRoom,
  },
  {
    path: "/termos-de-uso",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/financeiro",
    name: "Finances",
    component: Finances,
  },
  {
    path: "/saque",
    name: "Withdraw",
    component: Withdraw,
  },
  {
    path: "/amigos",
    name: "Friends",
    component: Friends,
  },
  {
    path: "/caixa-de-entrada",
    name: "Chat",
    component: Chat,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
