<template>
  <div class="container">
    <h2 class="inbox-title">Caixa de entrada</h2>
    <div class="col-md-12">
      <table class="table table-striped rounded">
        <thead class="thead-dark">
          <tr>
            <th>Assunto</th>
            <th>Nome</th>
            <th>Ações</th>
            <th>Mensagem</th>
          </tr>
        </thead>
        <tbody class="tbody-dark">
          <tr v-for="jogador in jogadores" :key="jogador.id">
            <td>{{ jogador.assunto }}</td>
            <td>{{ jogador.rementente }}</td>
            <td>
              <div class="flex-nowrap">
                <button class="button p-1 me-2" role="button">Aceitar</button>
                <button class="button p-1 me-2" role="button">Recusar</button>
              </div>
            </td>
            <td>
              <span>{{ jogador.mensagem }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatVue",
  methods: {},
  data() {
    return {
      jogadores: [
        {
          assunto: "Convite de amizade",
          rementente: "Daniel Fraga",
          mensagem: "Bora jogar corno",
        },
        {
          assunto: "Convite de amizade",
          rementente: "Daniel Fraga",
          mensagem: "Perdeu 2k otário!",
        },
      ],
    };
  },
  components: {},
};
</script>

<style>
@import url(@/views/styles/chat.css);
</style>
