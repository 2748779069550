<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card border-0 mt-lg-3 auth-card">
          <div class="card-body p-lg-5 pb-4 pt-4 px-4">
            <h5 class="auth-card-title text-left">Recuperar senha</h5>
            <form class="mt-lg-3">
              <div class="form-group mt-lg-3 mb-lg-3 mt-3 mb-3">
                <label>Endereço de e-mail para recuperar o acesso</label>
                <input
                  type="text"
                  class="form-control form-control-lg input mt-lg-3 mb-lg-3 white-text"
                  id="email"
                />
              </div>
            </form>
            <button
              class="auth-card-btn w-100 mt-lg-3 mb-lg-3 mt-4"
              @click="showCopyWarning"
            >
              Enviar link de recuperação
            </button>
            <div class="text-center mt-lg-2">
              <a href="/" class="to-login text-center">Voltar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";

export default {
  name: "RecoveryPassword",
  methods: {
    redirectToWithDraw() {
      this.$router.push({
        name: "Withdraw",
      });
    },
    showCopyWarning() {
      toast("Link de recuperação enviado com sucesso!", {
        autoClose: 3000,
        type: "success",
      }); // ToastOptions
    },
  },
  components: {},
};
</script>

<style>
@import url(@/views/styles/register.css);
</style>
