// src/hooks/useForm.js
import { reactive, toRefs } from "vue";

export function useForm(initialValues) {
  const state = reactive({
    values: { ...initialValues },
    errors: {},
  });

  const validate = () => {
    state.errors = {};

    // Validação do nome
    if (!state.values.name) {
      state.errors.name = "O nome é obrigatório.";
    }

    // Validação do email
    if (!state.values.email) {
      state.errors.email = "O email é obrigatório.";
    }

    // Validação da senha
    if (!state.values.password) {
      state.errors.password = "A senha é obrigatória.";
    }
    if (!state.values.agreeToTerms) {
      state.errors.agreeToTerms =
        "É necessario concordar com os termos de uso para se cadastrar.";
    }
  };

  const handleSubmit = (callback) => {
    validate();
    if (Object.keys(state.errors).length === 0 && callback) {
      callback(state.values);
    }
  };

  return { ...toRefs(state), handleSubmit };
}
