<template>
  <div class="container py-lg-1">
    <div class="flex-row d-flex flex-wrap mb-3">
      <div class="col-md-7">
        <h3 class="friends-title">Amigos</h3>
      </div>
      <div class="col-md-5 col-12">
        <div class="input-group">
          <input
            class="form-control search-bar"
            type="text"
            placeholder="Digite o nome ou ID do usuário"
            aria-label="Search"
          />
          <span class="input-group-text">
            <i class="fas fa-search"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-lg-3">
      <table class="table table-striped rounded">
        <thead class="thead-dark">
          <tr>
            <th>UID</th>
            <th>Status</th>
            <th>Nome</th>
            <th>Ganhos</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody class="tbody-dark">
          <tr v-for="(jogador, index) in jogadores" :key="jogador.id">
            <td>
              {{ index + 1 }}
              <i class="fa-solid fa-copy" @click="showCopyWarning"></i>
            </td>
            <td id="money">Online</td>
            <td><i class="fa-solid fa-crown"></i> {{ jogador.nome }}</td>
            <td id="money">
              {{ jogador.faturamento }}
            </td>
            <td>
              <button
                class="button p-1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Convidar
              </button>
              <ul class="dropdown-menu mt-2">
                <li>
                  <a class="dropdown-item mt-lg-2 mb-lg-2" href="/perfil"
                    ><i class="fa-solid fa-money-bill-transfer"></i>Partida
                    apostada</a
                  >
                </li>
                <li>
                  <a class="dropdown-item mt-lg-2 mb-lg-2" href="/amigos"
                    ><i class="fa-solid fa-gamepad"></i>Partida amistosa</a
                  >
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";

export default {
  name: "FriendsVue",
  methods: {
    showCopyWarning() {
      toast("ID copiado para a área de transferência", {
        autoClose: 3000,
        type: "success",
        position: "top-left",
      }); // ToastOptions
    },
  },
  data() {
    return {
      jogadores: [
        { id: 1, nome: "Bruno Da Silva Freitas", faturamento: "890.372,11" },
        {
          id: 2,
          nome: "Hélio Júnior Saraiva Gomes",
          faturamento: "890.372,11",
        },
        { id: 3, nome: "Edinaldo Pereira", faturamento: "60,00" },
        { id: 1, nome: "Carlos Eduardo de Souza", faturamento: "75,00" },
        { id: 2, nome: "Rodrigo Nascimento Silva", faturamento: "90,00" },
        { id: 3, nome: "Felipe Martins Correa", faturamento: "60,00" },
        { id: 1, nome: "Tiago dos Santos", faturamento: "75,00" },
        { id: 2, nome: "Lucas Alves de Menezes", faturamento: "90,00" },
        { id: 3, nome: "João Vitor Pereira", faturamento: "60,00" },
        { id: 1, nome: "Gustavo Henrique Farias", faturamento: "75,00" },
        { id: 2, nome: "Mateus Oliveira Costa", faturamento: "90,00" },
        { id: 3, nome: "Rafael Barbosa Carvalho", faturamento: "60,00" },
        { id: 1, nome: "Marcelo Dias Barbosa", faturamento: "75,00" },
        { id: 2, nome: "André Luiz de Almeida", faturamento: "90,00" },
        { id: 3, nome: "Thiago Moraes Santos", faturamento: "60,00" },
      ],
    };
  },
  components: {},
};
</script>

<style>
@import url(@/views/styles/friends.css);
</style>
