<template>
  <div class="container">
    <div
      class="col-md-12 card-container rounded pt-lg-5 pt-md-5 pt-4 pb-5 align-items-center justify-content-center d-flex"
    >
      <div class="flex-column text-center d-flex align-items-center">
        <div class="profile-photo-container">
          <img
            class="userphoto"
            src="@/components/assets/imgs/user.svg"
            alt="Foto de perfil"
            title="Clique para editar sua informações"
          />
          <!-- Ícone de lápis para editar -->
          <span
            v-show="!isAuthenticated"
            class="edit-icon"
            data-bs-toggle="modal"
            data-bs-target="#editUserInfoModal"
            >✏️</span
          >
        </div>

        <span class="id mt-3">ID: #2200032 </span>
        <div
          class="d-flex flex-wrap justify-content-center mt-lg-3 mb-lg-3 mt-3"
        >
          <div class="flex-column">
            <span>Vitórias</span>
            <div
              @click="increaseAmount5"
              class="badges-value-unselected m-lg-2 me-2"
            >
              1200
            </div>
          </div>

          <div class="flex-column">
            <span>Derrotas</span>
            <div
              @click="increaseAmount5"
              class="badges-value-unselected m-lg-2 me-2"
            >
              200
            </div>
          </div>

          <div class="flex-column">
            <span>Ganhos</span>
            <div
              @click="increaseAmount5"
              class="badges-value-unselected m-lg-2 me-2"
            >
              {{ $t("message.currentCoin") }} 12,00
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-lg-3 mt-3">
      <table class="table table-striped rounded">
        <thead class="thead-dark">
          <tr>
            <th>Ranking</th>
            <th>Nome</th>
            <th>Ganhos</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody class="tbody-dark">
          <tr v-for="(jogador, index) in jogadores" :key="jogador.id">
            <td>{{ index + 1 }}</td>
            <td><i class="fa-solid fa-crown"></i> {{ jogador.nome }}</td>
            <td id="money">
              {{ jogador.faturamento }}
            </td>
            <td>
              <button
                class="button p-1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Convidar
              </button>
              <ul class="dropdown-menu mt-2">
                <li>
                  <a class="dropdown-item mt-lg-2 mb-lg-2" href="/perfil"
                    ><i class="fa-solid fa-user-plus"></i>Adicionar como
                    amigo</a
                  >
                </li>
                <li>
                  <a class="dropdown-item mt-lg-2 mb-lg-2" href="/amigos"
                    ><i class="fa-solid fa-gamepad"></i>Partida amistosa</a
                  >
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <EditUserInfo />
</template>

<script>
import EditUserInfo from "@/components/modals/EditUserInfo.vue";

export default {
  name: "MyProfile",
  data() {
    return {
      jogadores: [
        {
          id: 2,
          nome: "Hélio Júnior Saraiva Gomes",
          faturamento: "12,00",
        },
      ],
      isAuthenticated: false,
    };
  },
  components: { EditUserInfo },
};
</script>

<style>
@import url(@/views/styles/my-profile.css);
</style>
