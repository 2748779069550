// Arquivo que exporta mensagens traduzidas para diferentes idiomas

export const messages = {
  // Mensagens em inglês
  en: {
    message: {
      hello: "Earn money by playing online chess!",
      playingNow: "Playing now",
      gamesPerDay: "games per day",
      train: "Train",
      play: "Play",
      currentCoin: "USD",
      depositary: "Depositary",
      username: "Name",
      earnings: "Earnings",
      home: "/home",
      addFunds: "Add funds",
      minimumDeposit: "Minimum deposit: 2 dollars",
      financial: "Financial",
      searchingPlayers: "Looking for players",
      myProfile: "My profile",
      friends: "Friends",
      withdraw: "Withdraw",
      exit: "Exit",
      name: "Name",
      email: "E-mail",
      codeIndication: "Referral code",
      password: "Password",
      register: "Register",
      haveAccount: "I already have an account",
      forgotPassword: "Forgot password",
      optional: "optional",
      login: "Login",
      playGame: "Play game",
      accountBalance: "Account balance",
      agreeToTerms: "Agree to the terms of use",
      changePassword: "Change password",
    },
  },
  // Mensagens em português do Brasil
  br: {
    message: {
      hello: "Ganhe dinheiro jogando xadrez online!",
      playingNow: "Jogando agora",
      gamesPerDay: "jogos por dia",
      train: "Treinar",
      play: "Jogar",
      currentCoin: "R$",
      depositary: "Depositar",
      username: "Nome",
      earnings: "Ganhos",
      home: "/pagina-de-inicio",
      addFunds: "Adicionar fundos",
      minimumDeposit: "Deposito mínimo: 2 reais",
      financial: "Financeiro",
      searchingPlayers: "Procurando jogadores",
      myProfile: "Meu perfil",
      friends: "Amigos",
      withdraw: "Sacar",
      exit: "Sair",
      name: "Nome",
      email: "E-mail",
      codeIndication: "Código de indicação",
      password: "Senha",
      register: "Cadastrar",
      haveAccount: "Já tenho uma conta",
      forgotPassword: "Recuperar senha",
      optional: "opcional",
      login: "Entrar",
      playGame: "Iniciar partida",
      accountBalance: "Saldo em conta",
      agreeToTerms: "Concordo com os termos de uso",
      changePassword: "Alterar senha",
    },
  },
};
