<template>
  <body>
    <div class="container">
      <div class="row d-flex justify-content-between mb-lg-2 mb-md-4 mb-sm-4">
        <div class="col-lg-7 col-md-7 col-xs-12 col-sm-12">
          <h2 class="title-home">
            {{ $t("message.hello") }}
          </h2>
          <div class="d-flex mt-lg-2">
            <h6 class="value me-3">
              <strong>0</strong> {{ $t("message.playingNow") }}
            </h6>
            <h6 class="value me-3">
              <strong>0</strong> {{ $t("message.gamesPerDay") }}
            </h6>
          </div>
          <div class="mt-lg-3 mt-md-3 mt-2 mb-lg-3 mb-md-3">
            <button type="button" class="btn-train me-2">
              {{ $t("message.train") }}
            </button>
            <a
              type="button"
              class="btn-play me-2"
              data-bs-toggle="modal"
              data-bs-target="#selectTheValueModal"
            >
              {{ $t("message.play") }}
            </a>
          </div>
        </div>

        <div
          class="col-lg-5 col-md-5 col-xs-12 col-sm-12 mt-lg-2 mt-md-2 mb-lg-3 mb-3 mt-3"
        >
          <div
            id="carouselExampleIndicators"
            class="carousel slide mt-lg-1"
            data-bs-ride="carousel"
            data-bs-interval="3000"
          >
            <div class="carousel-inner">
              <div class="carousel-item active rounded">
                <img
                  src="@/components/assets/imgs/download.png"
                  class="d-block w-100"
                  alt="Slide 1"
                />
              </div>
              <div class="carousel-item rounded">
                <img
                  src="@/components/assets/imgs/maxresdefault.jpg"
                  class="d-block w-100"
                  alt="Slide 2"
                />
              </div>
              <div class="carousel-item rounded">
                <img
                  src="@/components/assets/imgs/hq720.jpg"
                  class="d-block w-100"
                  alt="Slide 3"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
        <table class="table table-striped rounded">
          <thead class="thead-dark">
            <tr>
              <th>Ranking</th>
              <th>{{ $t("message.username") }}</th>
              <th>{{ $t("message.earnings") }}</th>
            </tr>
          </thead>
          <tbody class="tbody-dark">
            <tr
              v-for="(jogador, index) in jogadores"
              :key="jogador.id"
              @click="redirectToPlayProfile"
            >
              <td>{{ index + 1 }}</td>
              <td><i class="fa-solid fa-crown"></i> {{ jogador.nome }}</td>
              <td id="money">
                {{ $t("message.currentCoin") + " " + jogador.faturamento }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </body>
  <DrawerSidebar />
  <SelectTheValueModal />
</template>

<script>
import { SelectTheValueModal } from "@/components/modals";

export default {
  name: "HomePage",
  data() {
    return {
      jogadores: [
        { id: 1, nome: "Bruno Da Silva Freitas", faturamento: "890.372,11" },
        {
          id: 2,
          nome: "Hélio Júnior Saraiva Gomes",
          faturamento: "890.372,11",
        },
        { id: 3, nome: "Edinaldo Pereira", faturamento: "60,00" },
        { id: 1, nome: "Carlos Eduardo de Souza", faturamento: "75,00" },
        { id: 2, nome: "Rodrigo Nascimento Silva", faturamento: "90,00" },
        { id: 3, nome: "Felipe Martins Correa", faturamento: "60,00" },
        { id: 1, nome: "Tiago dos Santos", faturamento: "75,00" },
        { id: 2, nome: "Lucas Alves de Menezes", faturamento: "90,00" },
        { id: 3, nome: "João Vitor Pereira", faturamento: "60,00" },
        { id: 1, nome: "Gustavo Henrique Farias", faturamento: "75,00" },
        { id: 2, nome: "Mateus Oliveira Costa", faturamento: "90,00" },
        { id: 3, nome: "Rafael Barbosa Carvalho", faturamento: "60,00" },
        { id: 1, nome: "Marcelo Dias Barbosa", faturamento: "75,00" },
        { id: 2, nome: "André Luiz de Almeida", faturamento: "90,00" },
        { id: 3, nome: "Thiago Moraes Santos", faturamento: "60,00" },
      ],
    };
  },
  components: { SelectTheValueModal },
  methods: {
    redirectToFindPlayers() {
      this.$router.push({
        name: "FindPlayer",
      });
    },
    redirectToPlayProfile() {
      this.$router.push({
        name: "Profile",
      });
    },
  },
};
</script>

<style>
@import url(@/views/styles/home.css);
</style>
