import axiosInstance from "@/adapters/axios/axiosInstance";

const registerUserService = (name, email, codeIndication, password) => {
  return axiosInstance.post("/cadastro", {
    name: name,
    email: email,
    codigoIndicacao: codeIndication,
    password: password,
  });
};

export { registerUserService };
