<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card border-0 mt-lg-3 auth-card">
          <div class="card-body p-lg-5 pb-4 pt-4 px-4">
            <h5 class="auth-card-title text-left">
              {{ $t("message.register") }}
            </h5>
            <form class="mt-lg-3">
              <div class="form-group mt-lg-3 mb-lg-3 mt-3 mb-2">
                <label>{{ $t("message.name") }}</label>
                <input
                  v-model="values.name"
                  type="text"
                  class="form-control form-control-lg input mt-lg-3 mb-lg-1 white-text"
                  id="name"
                />
                <span class="error-text">{{ errors.name }}</span>
              </div>
              <div class="form-group mt-lg-3 mb-lg-3 mt-3 mb-2">
                <label>{{ $t("message.email") }}</label>
                <input
                  v-model="values.email"
                  type="email"
                  class="form-control form-control-lg input mt-lg-3 mb-lg-1 white-text"
                  id="email"
                />
                <span class="error-text">{{ errors.email }}</span>
              </div>
              <div class="form-group mt-lg-3 mb-lg-3 mt-3 mb-3">
                <label
                  >{{ $t("message.codeIndication") }}({{
                    $t("message.optional")
                  }})</label
                >
                <input
                  v-model="values.codeIndication"
                  type="text"
                  class="form-control form-control-lg input mt-lg-3 mb-lg-1 white-text"
                />
                <span class="error-text">{{ errors.codeIndication }}</span>
              </div>
              <div class="form-group mt-lg-3 mb-lg-3 mt-3 mb-3">
                <label>{{ $t("message.password") }}</label>
                <input
                  v-model="values.password"
                  type="password"
                  class="form-control form-control-lg input mt-lg-3 mb-lg-1 white-text"
                  id="password"
                />
                <span class="error-text">{{ errors.password }}</span>
              </div>
              <div class="form-check mt-lg-3 mb-lg-3 mt-3 mb-3">
                <input
                  v-model="values.agreeToTerms"
                  type="checkbox"
                  class="form-check-input"
                  id="agreeToTerms"
                />
                <label class="form-check-label" for="agreeToTerms">
                  {{ $t("message.agreeToTerms") }}
                </label>
              </div>
              <span class="error-text">{{ errors.agreeToTerms }}</span>
            </form>
            <button
              @click="handleUserRegister"
              class="auth-card-btn w-100 mt-lg-3 mb-lg-3 mt-4"
            >
              {{ $t("message.register") }}
            </button>
            <div class="text-center mt-lg-2 mt-3">
              <a class="to-login text-center" @click="redirectToLogin">
                {{ $t("message.haveAccount") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { registerUserService } from "@/services/registerUserService";
import { useForm } from "@/adapters/hooks/useForm";
import { toast } from "vue3-toastify";

export default {
  name: "RegisterPage",
  setup() {
    const { values, errors, handleSubmit } = useForm({
      name: "",
      email: "",
      codeIndication: "",
      password: "",
      agreeToTerms: false,
    });

    const handleUserRegister = () => {
      handleSubmit((formData) => {
        registerUserService(
          formData.name,
          formData.email,
          formData.codeIndication,
          formData.password,
          formData.agreeToTerms
        )
          .then((response) =>
            toast(response.data.mensagem, {
              type: "success",
              theme: "colored",
            })
          )
          .catch((error) => alert(error));
      });
    };

    return { values, errors, handleUserRegister };
  },
  methods: {
    redirectToLogin() {
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style>
@import url(@/views/styles/register.css);
</style>
