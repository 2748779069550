<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card border-0 mt-lg-3 auth-card">
          <div class="card-body p-lg-5 pb-4 pt-4 px-4">
            <h5 class="auth-card-title text-left">{{ $t("message.login") }}</h5>
            <form class="mt-lg-3">
              <div class="form-group mt-lg-3 mb-lg-3 mt-3 mb-3">
                <label> {{ $t("message.email") }}</label>

                <input
                  v-model="values.email"
                  type="text"
                  class="form-control form-control-lg input mt-lg-3 mb-lg-3 white-text"
                  id="email"
                />
                <span class="error-text">{{ errors.email }}</span>
              </div>
              <div class="form-group mt-lg-3 mb-lg-3 mt-3 mb-3">
                <label> {{ $t("message.password") }}</label>
                <input
                  v-model="values.password"
                  type="password"
                  class="form-control form-control-lg input mt-lg-3 mb-lg-3"
                  id="password"
                />
                <span class="error-text">{{ errors.password }}</span>
              </div>
              <a href="/recuperar-senha" id="recovery-password">
                {{ $t("message.forgotPassword") }}</a
              >
            </form>
            <button
              @click="handleLoginUser"
              class="auth-card-btn w-100 mt-lg-3 mb-lg-3 mt-4"
            >
              {{ $t("message.login") }}
            </button>
            <div class="text-center mt-lg-2">
              <a class="to-login text-center" @click="redirectToRegister">
                {{ $t("message.haveAccount") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLoginForm } from "@/adapters/hooks/useLoginForm";
import { loginUserService } from "@/services/loginUserService";
import { toast } from "vue3-toastify";
import { useRouter } from "vue-router"; // Importar useRouter

export default {
  name: "LoginPage",

  setup() {
    const router = useRouter(); // Usar useRouter para obter a instância do router

    const { values, errors, handleSubmit } = useLoginForm({
      email: "",
      password: "",
    });

    const handleLoginUser = () => {
      handleSubmit((formData) => {
        loginUserService(formData.email, formData.password).then((response) => {
          toast(response.data.mensagem, {
            type: "success",
            theme: "colored",
          });
          localStorage.setItem("@user", response.data.token);
          router.push({
            name: "Home",
          }); // Redirecionar para a rota Home
        });
      });
    };

    return {
      values,
      errors,
      handleLoginUser,
    };
  },
  methods: {
    redirectToRegister() {
      this.$router.push({
        name: "Register",
      });
    },
    redirectToHome() {
      this.$router.push({
        name: "Home",
      });
    },
  },

  components: {},
};
</script>

<style>
@import url(@/views/styles/register.css);
</style>
