<template>
  <div class="container">
    <h2
      class="title-findplayer d-flex align-items-center justify-content-center text-center mt-lg-3 mb-3"
    >
      {{ $t("message.searchingPlayers") }}
      <div class="loading-dots">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </h2>

    <div class="flex-row flex-wrap d-flex justify-content-between mt-lg-4">
      <div class="col-md-4 col-sm-6">
        <div class="d-flex align-items-center mt-lg-3 mt-3 mb-lg-3 mb-3">
          <img
            class="me-2 player-photo"
            src="@/components/assets/imgs/user.svg"
            alt="Jogador 1"
          />

          <div class="flex-column me-1">
            <h3 class="player-name">Saraiva077</h3>
            <h3 class="player-apostate">
              {{ $t("message.currentCoin") }} 100.000,00
            </h3>
          </div>
        </div>
        <div class="board shadow-blue me-3 mt-lg-3"></div>
      </div>
      <div
        class="col-md-4 col-sm-6 d-flex justify-content-center align-items-center"
      >
        <h2 class="vs-text">VS</h2>
      </div>

      <div class="col-md-4 col-sm-6">
        <div class="d-flex align-items-center mt-lg-3 mt-3 mb-lg-3 mb-3">
          <img
            class="me-2 player-photo"
            src="@/components/assets/imgs/user.svg"
            alt="Jogador 1"
          />

          <div class="flex-column me-1">
            <h3 class="player-name">Bruno</h3>
            <h3 class="player-apostate">
              {{ $t("message.currentCoin") }} 100.000,00
            </h3>
          </div>
        </div>
        <div class="board shadow-red me-3 mt-lg-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomLoading",
  methods: {
    redirectToGameRoom() {
      setTimeout(() => {
        this.$router.push({
          name: "GamesRoom",
        });
      }, 5000);
    },
    loadingEffectText() {
      setTimeout(() => {}, 500);
    },
  },
  mounted() {
    this.redirectToGameRoom();
  },
};
</script>

<style>
@import url(@/views/styles/find-player.css);
</style>
