// store.js

import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: false,
  },
  mutations: {
    SET_AUTHENTICATION(state, status) {
      state.isAuthenticated = status;
    },
  },
});

// export
