<template>
  <div class="container mt-lg-3 mt-3">
    <h6 class="withdraw-title mt-lg-3 mt-lg-3 mb-lg-3 mb-3">Sacar fundos</h6>

    <!-- Formulário de Saque -->
    <form @submit.prevent="processWithdrawal">
      <div class="mb-3">
        <label for="cpf" class="form-label">CPF</label>
        <input
          id="cpf"
          type="text"
          class="form-control"
          placeholder="CPF"
          v-model="cpf"
          v-mask="'###.###.###-##'"
          required
        />
      </div>

      <div class="mb-3">
        <label for="withdrawalAmount" class="form-label">Valor do Saque</label>
        <input
          id="withdrawalAmount"
          type="text"
          class="form-control"
          placeholder="Valor do saque"
          v-model="withdrawalAmount"
          v-mask="'R$ ##################'"
          required
        />
      </div>

      <div class="mb-3">
        <label for="pixKey" class="form-label">Chave PIX</label>
        <input
          id="pixKey"
          type="text"
          class="form-control"
          placeholder="Chave PIX"
          v-model="pixKey"
          required
        />
      </div>

      <button
        type="submit"
        class="btn btn-success withdraw-btn col-md-4 col-12 mt-lg-2 mt-2"
      >
        Processar Saque
      </button>
    </form>
  </div>
</template>

<script>
import VueMask from "v-mask";

export default {
  name: "WithdrawMoney",
  components: {},
  directives: {
    mask: VueMask.directive,
  },
  data() {
    return {
      withdrawalAmount: "",
    };
  },
  methods: {
    processWithdrawal() {
      // Lógica para processar o saque
    },
  },
};
</script>

<style>
@import url(@/views/styles/withdraw.css);
</style>
