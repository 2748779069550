import { createApp } from "vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App.vue";
import router from "@/router/routes";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue3-toastify/dist/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import VueTheMask from "vue-the-mask";
import { createI18n } from "vue-i18n";
import { messages } from "@/adapters/i18n/languages";
import store from "@/store/store"; // Importe seu store Vuex
import { chessboard } from "vue-chessboard";
import "vue-chessboard/dist/vue-chessboard.css";
import LottieAnimation from "lottie-web-vue";
import vueCountryRegionSelect from "vue3-ts-country-region-select";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

const app = createApp(App);
// Use o roteador criado
app.use(router);
// Use Spinner Loading
app.use(Loading);
// Use máscaras de texto
app.use(VueTheMask);
// Xadrez
app.use(chessboard);
// Internacionalização
// Lottie
app.use(LottieAnimation); // add lottie-animation to your global scope
app.use(vueCountryRegionSelect);
app.use(VueSidebarMenu);

const i18n = createI18n({
  locale: "br",
  messages,
});
app.use(i18n);
// Usar o store
app.use(store);

// Monte a aplicação
app.mount("#app");
