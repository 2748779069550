<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="editUserInfoModal"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content p-lg-3 p-md-3 p-3">
        <div class="modal-header">
          <h1 class="modal-title" id="exampleModalLabel">Editar perfil</h1>
          <i class="fa-solid fa-circle-xmark fs-3" data-bs-dismiss="modal"></i>
        </div>
        <div class="modal-body">
          <form class="mt-lg-1">
            <div class="form-group mt-lg-1 mb-lg-3 mt-3 mb-3">
              <label> {{ $t("message.username") }}</label>

              <input
                type="text"
                class="form-control form-control-lg input mt-lg-3 mb-lg-3 white-text"
                id="email"
              />
            </div>

            <a href="/recuperar-senha" id="recovery-password">
              {{ $t("message.forgotPassword") }}</a
            >
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-yellow-outlined"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button type="button" class="btn btn-yellow">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditUserInfo",
};
</script>
