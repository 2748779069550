import axiosInstance from "@/adapters/axios/axiosInstance";

const loginUserService = (email, password) => {
  return axiosInstance.post("/entrar", {
    email: email,
    password: password,
  });
};

export { loginUserService };
