<template>
  <!--Modal-->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog d-flex">
      <div class="modal-content p-lg-3 p-md-3 p-3">
        <div
          class="modal-header text-center align-items-center justify-content-between"
        >
          <h2 v-if="isPaymentMode" class="modal-title">
            Detalhes do pagamento
          </h2>
          <h2 v-else class="modal-title">{{ $t("message.addFunds") }}</h2>
          <i class="fa-solid fa-circle-xmark fs-3" data-bs-dismiss="modal"></i>
        </div>
        <div class="modal-body text-center">
          <div
            v-if="isPaymentMode"
            class="d-flex justify-content-around mb-2 mt-lg-2"
          >
            <img
              class="pix-brand"
              src="@/components/assets/imgs/pix.svg"
              alt="Pix"
            />
            <h2 class="pix-amount">
              {{ $t("message.currentCoin") }} {{ amount.toFixed(2) }}
            </h2>
          </div>
          <div v-else class="d-flex align-items-center justify-content-center">
            <h3 class="amount">
              {{ $t("message.currentCoin") }} {{ amount.toFixed(2) }}
            </h3>
          </div>
          <div v-if="isPaymentMode"></div>
          <div
            v-else
            class="d-flex flex-md-nowrap flex-lg-nowrap flex-wrap justify-content-center mt-2"
          >
            <div
              @click="increaseAmount1"
              class="badges-value-unselected me-2 mt-lg-2 mb-lg-2 mt-md-2 mb-2"
            >
              +{{ $t("message.currentCoin") }} 1,00
            </div>
            <div
              @click="increaseAmount5"
              class="badges-value-unselected me-2 mt-lg-2 mb-lg-2 mt-md-2 mb-2"
            >
              +{{ $t("message.currentCoin") }} 5,00
            </div>
            <div
              @click="increaseAmount10"
              class="badges-value-unselected me-2 mt-lg-2 mb-lg-2 mt-md-2 mb-2"
            >
              +{{ $t("message.currentCoin") }} 10,00
            </div>
          </div>
          <div class="mt-lg-2 mt-2 mb-lg-2 mb-2">
            <qrcode-vue
              v-show="isPaymentMode"
              :value="code"
              class="qr-code"
              size="200"
            />
            <p v-show="isPaymentMode" class="pix-key text-center mt-2">
              enial.com/qrs1/v2/01uBa67jGM9tK2fGu6e86MrbV<br />4dGsXt0yLq7H531atw0G7yJN5204000053039<br />8654041.005802BR5922SAFEPAG<br />
              PAGAMENTOS S A6012Porto Alegre62070503***63040087
              <i class="fa-solid fa-copy" @click="showCopyWarning()"></i>
            </p>
          </div>
        </div>

        <div
          class="modal-footer d-flex text-center align-items-center justify-content-center"
        >
          <p v-show="!isPaymentMode" class="minimum-deposit mb-3">
            {{ $t("message.minimumDeposit") }}
          </p>

          <button class="w-100 rounded-btn" @click="setPaymentMode">
            {{ $t("message.depositary") }}
          </button>
          <a
            @click="disablePaymentMode()"
            v-show="isPaymentMode"
            href="#"
            class="minimum-deposit mt-3"
          >
            Voltar
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { toast } from "vue3-toastify";

export default {
  name: "BetAmountModal",
  data() {
    return {
      amount: 2.0, // Valor inicial
      isPaymentMode: false,
      code: "",
      customAmount: 2.0, // Nova propriedade para texto editável
    };
  },
  methods: {
    increaseAmount1() {
      this.amount += 1.0; // Aumenta o valor em 1.00
    },
    increaseAmount5() {
      this.amount += 5.0; // Aumenta o valor em 1.00
    },
    increaseAmount10() {
      this.amount += 10.0; // Aumenta o valor em 1.00
    },
    decreaseAmount() {
      if (this.amount > 1.0) {
        this.amount -= 1.0; // Diminui o valor em 1.00, mantendo o mínimo em 1.00
      }
    },
    setPaymentMode() {
      this.isPaymentMode = true;
    },
    disablePaymentMode() {
      this.isPaymentMode = false;
    },
    showCopyWarning() {
      toast("O código PIX foi cópiado com sucesso.", {
        autoClose: 3000,
        type: "success",
        theme: "colored",
      }); // ToastOptions
    },
    showLosses() {
      toast(
        "Infelizmente você perdeu   80.000,00! o valor já foi débitado de sua carteira",
        {
          autoClose: 3000,
          type: "error",
          theme: "colored",
        }
      ); // ToastOptions
    },
  },

  components: {
    QrcodeVue,
  },
};
</script>

<style>
@import url(@/components/assets/css/modals.css);
@import url(@/components/assets/css/badges.css);
</style>
